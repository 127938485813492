<template>
  <a-modal :visible="shown"
           :title="title"
           :confirm-loading="loading || uploading"
           :mask-closable="false"
           :destroy-on-close="true"
           :after-close="reset"
           width="600px"
           @ok="onSubmit"
           @cancel="close()">
    <a-form :form="form"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }">
      <a-form-item label="岗位">
        <app-select-job v-decorator="['post_id', decorator.post_id]"
                        name="post_id"
                        mode="default"
                        class="select-job"
                        placeholder="请选择岗位"/>
      </a-form-item>

      <a-form-item label="入口文案">
        <a-input v-decorator="['title', decorator.title]"
                 :allow-clear="true"
                 name="title"
                 placeholder="请填写入口文案"/>
      </a-form-item>

      <a-form-item label="状态">
        <a-radio-group v-decorator="['is_open', decorator.is_open]" name="is_open">
          <a-radio v-for="(item) in statusList" :key="item.id" :value="item.id">{{ item.name }}</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="优先级">
        <a-input-number v-decorator="['sort_num', decorator.sort_num]"
                        :min="0"
                        :formatter="value => `${value}`.replace(/\D*/g, '')"
                        :parser="value => `${value}`.replace(/\D*/g, '')"
                        name="sort_num"
                        placeholder="请填写优先级"
                        style="width: 180px;"/>
      </a-form-item>

    </a-form>
  </a-modal>
</template>

<script>
import {
  getShowcasePostDetail,
  updateShowcasePost,
} from '../api/config';

const decorator = {
  post_id: {
    rules: [{ required: true, message: '请选择岗位' }],
  },
  title: {
    rules: [{ required: true, message: '请填写入口文案' }],
  },
  is_open: {
    rules: [{ required: true, message: '请选择状态' }],
  },
  sort_num: {
    rules: [{ required: true, message: '请填写优先级' }],
  },
};

export default {
  name: 'editPostModal',
  inject: ['activityId'],
  computed: {
    title() {
      return this.id ? '修改荣耀展台岗位信息' : '添加荣耀展台岗位信息';
    },
  },
  data() {
    return {
      loading: false,
      uploading: false, // 是否正在上传图片
      shown: false,

      statusList: [
        { id: 0, name: '关闭' },
        { id: 1, name: '开启' },
      ],
      decorator,

      id: '',
      stageId: '',

      jobName: '',
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {});
  },
  methods: {
    show({ id, stageId } = {}) {
      this.id = id;
      this.stageId = stageId;
      this.getPostDetail();

      this.shown = true;

      if (!id) {
        this.$nextTick(() => this.setDecorator({
          is_open: 0,
          sort_num: 1,
        }));
      }
    },
    close() {
      this.shown = false;
    },
    reset() {
      this.id = '';
      this.stageId = '';
    },

    async getPostDetail() {
      if (!this.id) {
        return;
      }

      this.loading = true;
      const data = await getShowcasePostDetail(this.id).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取阶段详情失败');
        return;
      }
      this.setDecorator(data?.data || {});
    },

    onSubmit() {
      this.form.validateFields((error, values) => {
        if (!error) {
          const params = {
            ...values,
          };

          if (this.id) {
            params.id = this.id;
          }
          params.activity_id = this.activityId;
          params.activity_module_id = this.stageId;

          this.save(params);
        }
      });
    },
    async save(params) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await updateShowcasePost(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');

      const evt = {
        ...params,
        stageId: this.stageId,
      };
      if (this.jobName) {
        evt.post_name = this.jobName;
      }
      this.$emit('saved', evt);
      this.close();
    },

    setDecorator(data = {}) {
      const values = {};
      Object.keys(this.decorator).forEach((key) => {
        if (data[key] !== undefined && data[key] !== null) {
          values[key] = data[key];
        }
      });

      if (values.post_id) {
        values.post_id = values.post_id + '';
      }

      this.form.setFieldsValue(values);
    },

    jobChanged(ids, items) {
      this.jobName = items?.[0]?.name || '';
    },
  },
};
</script>

<style scoped lang="less">
.select-job {
  transform: translateY(5px);
}
</style>
