<template>
  <SelectWithPager placeholder="请选择阶段"
                   v-bind="$attrs"
                   :list="list"
                   :options="{id: 'id', name: 'title'}"
                   v-on="$listeners"/>
</template>

<script>
import { getStageListForSelect } from '../api/select';

import SelectWithPager from '../../../../components/select/SelectWithPager.vue';

export default {
  name: 'selectStage',
  components: {
    SelectWithPager,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String },
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getStageList();
  },
  methods: {
    async getStageList() {
      const data = await getStageListForSelect();

      this.list = data?.data || [];
    },
  },
};
</script>

<style scoped lang="less">

</style>
