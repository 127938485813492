import { stringify } from 'qs';

import { baseUrl } from '../../../../Config';
import store from '../../../../store/index';
import request from '../../../../utils/Request';
import download from '../../../../utils/download/download';

// 首页阶段环节 -- start
// 阶段环节列表
export function getStageList(params) {
  return request({
    url: '/activity/admin/config/index',
    method: 'get',
    params,
  });
}

// 阶段修改信息页面
export function getStageDetail(params) {
  return request({
    url: '/activity/admin/config/getActivityModuleInfo',
    method: 'get',
    params,
  });
}

// 添加/修改阶段
export function updateStage(data) {
  return request({
    url: '/activity/admin/config/upActivityModule',
    method: 'post',
    data,
  });
}

// 修改阶段状态
export function updateStageStatus(data) {
  return request({
    url: '/activity/admin/config/upActivityModuleStatus',
    method: 'post',
    data,
  });
}

// 删除阶段
export function deleteStage(data) {
  return request({
    url: '/activity/admin/config/delActivityModule',
    method: 'delete',
    data,
  });
}


// 环节修改信息页面
export function getSegmentDetail(params) {
  return request({
    url: '/activity/admin/config/getLinkInfo',
    method: 'get',
    params,
  });
}

// 添加/修改环节信息
export function updateSegment(data) {
  return request({
    url: '/activity/admin/config/uplinkData',
    method: 'post',
    data,
  });
}

// 修改环节状态
export function updateSegmentStatus(data) {
  return request({
    url: '/activity/admin/config/upLinkStatus',
    method: 'post',
    data,
  });
}

// 删除环节
export function deleteSegment(data) {
  return request({
    url: '/activity/admin/config/delLinkData',
    method: 'delete',
    data,
  });
}

// 首页阶段环节 -- end

// 页面头图 -- end
// 活动轮播图--显示
export function getPictureDetail(params) {
  return request({
    url: `/activity/admin/config/getShufflingPic/${params.activity_id}`,
    method: 'get',
    params,
  });
}

// 活动轮播图--保存
export function setPictureDetail(params, data) {
  return request({
    url: `/activity/admin/config/addShufflingPic/${params.activity_id}`,
    method: 'post',
    data,
  });
}

// 页面头图 -- end

// 作品上传 -- start
// 作品上传入口
export function getOpusList(params) {
  params.type = 4;
  return request({
    url: `/activity/admin/config/getLinkDetailsList`,
    method: 'get',
    params,
  });
}

// 修改作品上传
export function getOpusDetail(params) {
  return request({
    url: `/activity/admin/config/getDetailsInfo`,
    method: 'get',
    params,
  });
}

// 添加/修改作品上传
export function updateOpus(data) {
  data.type = 4;
  return request({
    url: `/activity/admin/config/upLinkDetails`,
    method: 'post',
    data,
  });
}

// 修改作品上传状态
export function updateOpusStatus(data) {
  return request({
    url: `/activity/admin/config/updDetailsStatus`,
    method: 'post',
    data,
  });
}

// 删除作品上传
export function deleteOpus(data) {
  return request({
    url: `/activity/admin/config/delDetailsData`,
    method: 'delete',
    data,
  });
}

// 作品上传 -- end

// 考试入口 -- start
// 考试入口列表
export function getExamList(params) {
  params.type = 3;
  return request({
    url: `/activity/admin/config/getLinkDetailsList`,
    method: 'get',
    params,
  });
}

// 修改考试
export function getExamDetail(params) {
  return request({
    url: `/activity/admin/config/getDetailsInfo`,
    method: 'get',
    params,
  });
}

// 添加/修改考试
export function updateExam(data) {
  data.type = 3;
  return request({
    url: `/activity/admin/config/upLinkDetails`,
    method: 'post',
    data,
  });
}

// 修改考试状态
export function updateExamStatus(data) {
  return request({
    url: `/activity/admin/config/updDetailsStatus`,
    method: 'post',
    data,
  });
}

// 删除考试
export function deleteExam(data) {
  return request({
    url: `/activity/admin/config/delDetailsData`,
    method: 'delete',
    data,
  });
}

// 考试入口 -- end


// 荣耀展台 -- start
// 荣耀展台-阶段列表
export function getShowcaseStageList(params) {
  return request({
    url: `/activity/admin/honour/post/getModuleList`,
    method: 'get',
    params,
  });
}

// 荣耀展台-阶段下岗位列表
export function getShowcasePostList(id) {
  return request({
    url: `/activity/admin/honour/post/list/${id}`,
    method: 'get',
  });
}

// 荣耀展台-阶段下岗位配置详情
export function getShowcasePostDetail(id) {
  return request({
    url: `/activity/admin/honour/post/${id}`,
    method: 'get',
  });
}

// 荣耀展台-阶段下岗位配置添加
export function updateShowcasePost(data) {
  return request({
    url: `/activity/admin/honour/post`,
    method: 'post',
    data,
  });
}

// 荣耀展台-阶段下岗位配置删除
export function deleteShowcasePost(id) {
  return request({
    url: `/activity/admin/honour/post/${id}`,
    method: 'delete',
  });
}

// 荣耀展台-阶段下岗位配置开启关闭
export function updateShowcasePostStatus(id, data) {
  return request({
    url: `/activity/admin/honour/post/open/${id}`,
    method: 'post',
    data,
  });
}

// 荣耀展台-岗位榜单配置列表
export function getShowcasePostBillboardList(id) {
  return request({
    url: `/activity/admin/honour/posttop/list/${id}`,
    method: 'get',
  });
}

// 荣耀展台-岗位榜单配置详情
export function getShowcasePostBillboardDetail(id) {
  return request({
    url: `/activity/admin/honour/posttop/${id}`,
    method: 'get',
  });
}

// 荣耀展台-岗位榜单配置添加
export function updateShowcasePostBillboard(data) {
  return request({
    url: `/activity/admin/honour/posttop`,
    method: 'post',
    data,
  });
}

// 荣耀展台-岗位榜单配置删除
export function deleteShowcasePostBillboard(id) {
  return request({
    url: `/activity/admin/honour/posttop/${id}`,
    method: 'delete',
  });
}

// 荣耀展台-岗位榜单配置开启关闭
export function updateShowcasePostBillboardStatus(id, data) {
  return request({
    url: `/activity/admin/honour/posttop/open/${id}`,
    method: 'post',
    data,
  });
}

// 荣耀展台-榜单导入模板
export function getShowcasePostBillboardImportTemplate() {
  return request({
    url: `/public/conf/activity/honourpostlist/toptemplate`,
    method: 'get',
  });
}

// 荣耀展台-导入荣耀榜单用户
export function uploadShowcasePostBillboardFile(data) {
  return request({
    url: `/activity/admin/honour/postTopUser/import`,
    method: 'post',
    data,
  });
}

// 荣耀展台 -- end

// 上传作品打分 -- end
// 作品上传列表
export function getWorksList(params) {
  return request({
    url: `/activity/admin/config/uploadWorksList`,
    method: 'get',
    params,
  });
}

// 作品上传列表 - 导出
export function getWorksListExport(params) {
  const path = `${baseUrl}/activity/admin/config/uploadWorksList?${stringify(params, { encode: false })}`;
  return download({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 作品打分
export function toGradeWork(data) {
  return request({
    url: `/activity/admin/config/uploadWorksScore`,
    method: 'post',
    data,
  });
}

// 作品打分记录
export function getGradeRecord(params) {
  return request({
    url: `/activity/admin/config/UserScoreLogList`,
    method: 'get',
    params,
  });
}

// 上传作品打分 -- end
