<template>
  <a-select v-model="fileType"
            v-bind="$attrs"
            placeholder="请选文件择类型"
            @change="onChange">
    <a-select-option v-for="(item) in typeList" :key="item.id" :value="item.id">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: 'selectFileType',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: [String, Number] },
  },
  watch: {
    value: {
      immediate: true,
      handler(val, oldVal) {
        if (val === oldVal) {
          return;
        }
        this.fileType = val;
      },
    },
  },
  data() {
    return {
      fileType: undefined,

      typeList: [
        { id: 1, name: 'doc文档' },
        { id: 2, name: 'xls表格' },
        { id: 3, name: 'pdf' },
        { id: 4, name: 'ppt' },
        { id: 5, name: 'picture图片' },
        { id: 6, name: 'video视频' },
        { id: 7, name: 'voice音频' },
        { id: 8, name: 'other其他' },
      ],
    };
  },
  methods: {
    onChange(evt) {
      this.$emit('change', this.fileType, evt);
    },
  },
};
</script>

<style scoped lang="less">

</style>
