import { saveAs } from 'file-saver';

const defaultParams = {
  // 可选参数
  // url: '',
  // token: '',
  // fileName: '',
  //
  // timeout: 0,
  // headers: [],
  // withCredentials: true,
  // method: 'get',
  //
  // process: (event) => event,
  // request: null,

  timeout: 0,
  headers: [],
  withCredentials: false,
  method: 'GET',
};

function createRequest(params) {
  if (!params?.url) {
    return undefined;
  }

  const request = new XMLHttpRequest();

  request.open(params.method || 'GET', params.url, true);
  request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  if (params.headers) {
    params.headers.forEach((header) => {
      request.setRequestHeader(header.name, header.value);
    });
  }
  request.responseType = 'arraybuffer';
  if (params.process && typeof params.process === 'function') {
    request.addEventListener('progress', params.process);
  }
  if (params.timeout) {
    request.timeout = params.timeout;
  }
  request.withCredentials = !!params.withCredentials;

  params.request = request;

  return params;
}

function fetchData(request) {
  return new Promise((resolve, reject) => {
    if (!request) {
      reject('[download] CreateRequestError: No Url!!!');
      return;
    }

    request.onload = () => {
      if (parseInt(`${request.status}`, 10) !== 200) {
        reject(`[download] status code [${request.status}]`);
        return;
      }

      const arrayBuffer = request.response;

      const blob = new Blob([arrayBuffer]);
      const reader = new FileReader();
      reader.readAsText(blob, 'utf-8');
      reader.onload = () => {
        try {
          const response = JSON.parse(reader.result);
          reject({
            type: 'origin',
            response,
          });
        } catch (e) {
          resolve({
            type: 'file',
            blob,
            arrayBuffer: request.response,
          });
        }
      };
    };

    request.ontimeout = () => {
      reject('[download] request timeout');
    };

    request.onerror = () => {
      reject('[download] network error');
    };

    request.send();
  });
}

function getFileName(params) {
  if (!params) {
    return new Date().getTime().toString(10);
  }
  if (params.fileName) {
    return params.fileName;
  }
  // Trying to get file name from response header
  const content = params.request.getResponseHeader('Content-Disposition');
  let contentParts = [];

  if (content) {
    contentParts = content.replace(/["']/g, '').match(/filename\*?=([^;]+)/);
  }

  const extractedName = contentParts && contentParts.length >= 1 ? contentParts[1] : (
    params.url ? params.url.split('/').pop()?.split('?')[0] : ''
  );

  return decodeURIComponent(extractedName);
}

/**
 * 下载文件
 * @param options 参数详细见 defaultParams
 * @returns {Promise<{type: *}>}
 */
function download(options) {
  const params = createRequest({
    ...defaultParams,
    ...(options || {}),
    headers: [
      ...(options?.headers || []),
      { name: 'X-Auth-Token', value: options.token },
    ],
  });

  return fetchData(params?.request).then((result) => {
    saveAs(result.blob, getFileName(params));
    return { type: result.type };
  });
}

export default download;
