<template>
  <div>
    <app-loading-status :non-data="!(list && list.length)" :is-loading="loading"/>

    <div class="ph-20">
      <a-collapse v-if="list && list.length" v-model="activeKey">
        <a-collapse-panel v-for="item of list" :key="item.id.toString()" :header="null">
          <div slot="extra" class="flex space-between panel-extra">
            <div class="flex flex-start flex-wrap">
              <span class="flex-none mr-20">{{ item.sort }}阶段：{{ item.activity_module_name }}</span>
            </div>

            <div class="flex flex-none" @click.stop="">
              <a-button type="link" @click="addExam(item)">添加考试</a-button>
            </div>
          </div>

          <template v-if="item.children && item.children.length">
            <a-table :columns="columns"
                     :data-source="item.children"
                     :pagination="false"
                     :loading="loading"
                     :scroll="{ x: '100%' }"
                     row-key="id"
                     class="mb-10 table small-cell-table">
              <template slot="action" slot-scope="text, record">
                <a-button type="link" @click="editExam(record, item)">编辑</a-button>
                <a-button type="link" @click="changeExamStatus(record)">
                  {{ +record.status === 1 ? '关闭' : '开启' }}
                </a-button>
                <a-button type="link" @click="deleteExamUpload(record)">删除</a-button>
              </template>
            </a-table>
          </template>
          <template v-else>
            <div class="row row-center">暂无数据</div>
          </template>
        </a-collapse-panel>
      </a-collapse>
    </div>

    <EditExamModal ref="editExamModalRef"
                   @saved="updateExamForce"/>
  </div>
</template>

<script>
import moment from 'moment';
import {
  getExamList,
  updateExamStatus,
  deleteExam,
} from '../api/config';

import EditExamModal from '../components/editExamModal.vue';

const columns = [
  {
    width: '180px',
    align: 'center',
    dataIndex: 'post_name',
    key: 'post_name',
    title: '岗位',
  },
  {
    width: '180px',
    align: 'center',
    dataIndex: 'primary_title',
    key: 'primary_title',
    title: '入口名称',
  },
  {
    width: '180px',
    align: 'center',
    dataIndex: 'course_name',
    key: 'course_name',
    title: '课程名称',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'course_task_name',
    key: 'course_task_name',
    title: '考试名称',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'start_time',
    key: 'start_time',
    title: '开始时间',
    customRender: (text) => text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : '--',
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'end_time',
    key: 'end_time',
    title: '结束时间',
    customRender: (text) => text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : '--',
  },
  {
    width: '60px',
    align: 'center',
    dataIndex: 'status_name',
    key: 'status_name',
    title: '状态',
  },
  {
    width: '160px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    fixed: 'right',
  },
];

export default {
  name: 'exam',
  components: {
    EditExamModal,
  },
  inject: ['activityId'],
  data() {
    return {
      loading: false,

      activeKey: [],

      columns,
      list: [],
    };
  },
  created() {
    this.getExamList();
  },
  methods: {
    async getExamList() {
      this.loading = true;
      const data = await getExamList({
        activity_id: this.activityId,
      }).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取数据失败');
        return;
      }
      this.list = (data?.data || []).map((i) => ({
        ...i,
        id: i.activity_module_id,
        children: i.activity_link_list,
      }));
      this.activeKey = this.list.map((i) => i.id);
    },

    addExam(record) {
      this.showEditExamModal(undefined, record);
    },
    editExam(item, record) {
      this.showEditExamModal(item.id, record);
    },
    showEditExamModal(id, record) {
      if (this.$refs.editExamModalRef?.show) {
        this.$refs.editExamModalRef.show({
          id,
          stageId: record?.id,
          record,
        });
      }
    },
    updateExamForce() {
      this.getExamList();
    },
    updateExam(params) {
      const [item] = this.list.flatMap(
        (i) => i.children?.length ? i.children : [],
      ).filter(
        (i) => i.id === params.id,
      ) || [];

      if (!item?.id) {
        this.getExamList();
        return;
      }

      Object.keys(params).forEach((key) => {
        item[key] = params[key];
      });
    },

    async changeExamStatus(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await updateExamStatus({
        id: record.id,
        status: (record.status + 1) % 2,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '状态修改失败');
        return;
      }
      this.$message.success(data?.message || '状态修改成功');
      this.list.forEach((item) => {
        if (item.children?.length) {
          item.children.forEach((i) => {
            if (i.id === record.id) {
              i.status = (record.status + 1) % 2;
              i.status_name = i.status ? '开启' : '关闭';
            }
          });
        }
      });
    },

    deleteExamUpload(record) {
      this.$confirm({
        title: '提示',
        content: `确认删除上传入口 "${record.primary_title}" ？`,
        onOk: () => this.confirmdeleteExamUpload(record),
      });
    },
    async confirmdeleteExamUpload(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await deleteExam({ id: record.id }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data?.message || '删除成功');
      this.list.forEach((item) => {
        if (item.children?.length) {
          const index = item.children.findIndex((i) => i.id === record.id);
          if (index > -1) {
            item.children.splice(index, 1);
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .ant-collapse-extra {
  float: none !important;
}
</style>
