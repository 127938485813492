<template>
  <SelectWithPager placeholder="请选择课程"
                   v-bind="$attrs"
                   v-model="selectedIds"
                   :options="{id: 'id', name: 'title'}"
                   api="/public/conf/course/searchtitle"
                   :params="{shutoff: shelfStatus}"
                   v-on="$listeners"/>
</template>

<script>
import SelectWithPager from './SelectWithPager.vue';

export default {
  name: 'SelectCourse',
  components: {
    SelectWithPager,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { type: String },
    shelfStatus: { type: [String, Number], default: 0 }, // 课程的上下架状态 0：上架 ；1：下架
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.selectedIds = this.value;
        this.setSelectedItems();
      },
    },
  },
  data() {
    return {
      selectedIds: '',
    };
  },
  methods: {
    setSelectedItems() {
      // if (this.selectList?.length && this.selectedIds) {
      //   this.selectedItems = this.selectList.filter(
      //     (i) => this.selectedIds.split(',').filter(
      //       (i) => !!i,
      //     ).map(
      //       (i) => +i,
      //     ).includes(i.id),
      //   );
      // }
    },
  },
};
</script>
