<template>
  <div>
    <div class="flex flex-end ph-20">
      <a :href="templateUrl"
         download
         class="ant-btn ant-btn-primary">
        下载{{ templateName }}
      </a>
    </div>

    <app-loading-status :non-data="!(list && list.length)" :is-loading="loading"/>

    <div v-for="(stage) in list" :key="stage.id" class="ph-20 pb-20">
      <div class="flex space-between">
        <div class="pt-20">
          <h2> {{ stage.sort }}阶段：{{ stage.phase_title }} </h2>
        </div>

        <div>
          <a-button type="primary" @click="addPost(stage)">添加岗位</a-button>
        </div>
      </div>

      <app-loading-status :non-data="!(stage && stage.children && stage.children.length)"
                          :is-loading="stage.loading">
        <div class="flex">暂无数据</div>
      </app-loading-status>

      <a-collapse v-if="stage && stage.children && stage.children.length" v-model="stage.activeKey">
        <a-collapse-panel v-for="post of stage.children" :key="post.id.toString()" :header="null">
          <div slot="extra" class="flex space-between panel-extra">
            <div class="flex flex-start flex-wrap">
              <span class="flex-none mr-20">{{ post.post_name }}</span>
              <span class="flex-none mr-20">入口文案：{{ post.title }}</span>
              <span class="flex-none mr-20">优先级：{{ post.sort_num }}</span>
              <span class="flex-none mr-20">当前状态：{{ post.is_open ? '开启' : '关闭' }}</span>
            </div>

            <div class="flex flex-none" @click.stop="">
              <a-button type="link" @click="addBillboard(post, stage)">添加榜单</a-button>
              <a-button type="link" @click="changePostStatus(post)">
                {{ +post.is_open === 1 ? '关闭' : '开启' }}
              </a-button>
              <a-button type="link" @click="editPost(post, stage)">编辑</a-button>
              <a-button type="link" @click="deletePost(post, stage)">删除</a-button>
            </div>
          </div>

          <template v-if="post.children && post.children.length">
            <a-table :columns="columns"
                     :data-source="post.children"
                     :pagination="false"
                     :loading="post.loading"
                     row-key="id"
                     :scroll="{ x: '100%' }"
                     class="mb-10 table small-cell-table">
              <template slot="head_pic" slot-scope="text, record">
                <app-image-viewer :url="record.head_pic_data.url"
                                  class="preview-image"/>
              </template>

              <template slot="action" slot-scope="text, record">
                <a-button type="link" @click="editBillboard(record, post, stage)">编辑</a-button>
                <a-button type="link" @click="changeBillboardStatus(record, post)">
                  {{ +record.is_open === 1 ? '关闭' : '开启' }}
                </a-button>
                <a-button type="link" @click="deleteBillboard(record, post)">删除</a-button>

                <a-upload :multiple="false"
                          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          :disabled="uploading"
                          :show-upload-list="false"
                          :custom-request="(evt) => importBillboardFile(record, post, evt)">
                  <a-button type="link">导入数据</a-button>
                </a-upload>
              </template>
            </a-table>
          </template>
          <template v-else>
            <app-loading-status :non-data="!(post && post.children && post.children.length)"
                                :is-loading="post.loading">
              <div slot="empty" class="flex">暂无数据</div>
            </app-loading-status>
          </template>
        </a-collapse-panel>
      </a-collapse>
    </div>

    <EditPostModal ref="editPostModalRef"
                   @saved="updatePostForce"/>
    <EditBillboardModal ref="editBillboardModalRef"
                        @saved="updateBillboardForce"/>
  </div>
</template>

<script>
import {
  getShowcasePostBillboardImportTemplate,
  uploadShowcasePostBillboardFile,

  getShowcaseStageList,
  getShowcasePostList,
  getShowcasePostBillboardList,

  updateShowcasePostStatus,
  deleteShowcasePost,

  updateShowcasePostBillboardStatus,
  deleteShowcasePostBillboard,
} from '../api/config';

import EditPostModal from '../components/editPostModal.vue';
import EditBillboardModal from '../components/editBillboardModal.vue';

const columns = [
  {
    width: '180px',
    align: 'center',
    dataIndex: 'honour_post_data.post_name',
    key: 'honour_post_data.post_name',
    title: '岗位',
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'title',
    key: 'title',
    title: '榜单名称',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'sort_num',
    key: 'sort_num',
    title: '同岗位下优先级',
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'head_pic',
    key: 'head_pic',
    title: '头图',
    scopedSlots: { customRender: 'head_pic' },
  },
  {
    width: '100px',
    align: 'center',
    dataIndex: 'top_user_num',
    key: 'top_user_num',
    title: '展示人数',
  },
  {
    width: '60px',
    align: 'center',
    dataIndex: 'is_open',
    key: 'is_open',
    title: '状态',
    customRender: (text) => text ? '开启' : '关闭',
  },
  {
    width: '240px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    fixed: 'right',
  },
];

export default {
  name: 'showcase',
  components: {
    EditPostModal,
    EditBillboardModal,
  },
  inject: ['activityId'],
  data() {
    return {
      loading: false,
      uploading: false,

      templateName: '',
      templateUrl: '',

      columns,
      list: [],
    };
  },
  created() {
    this.getShowcasePostBillboardImportTemplate();
    this.getShowcaseStageList();
  },
  methods: {
    async getShowcasePostBillboardImportTemplate() {
      const data = await getShowcasePostBillboardImportTemplate();

      this.templateName = data?.data?.activity_honour_post_list_import?.title || '';
      this.templateUrl = data?.data?.activity_honour_post_list_import?.url || '';
    },

    // 获取三级数据
    async getShowcaseStageList() {
      this.loading = true;
      const data = await getShowcaseStageList({
        activity_id: this.activityId,
      }).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        return;
      }
      this.list = data?.data || [];

      this.list.forEach((stage) => {
        this.getShowcasePostList(stage);
      });
    },
    async getShowcasePostList(stage) {
      stage.loading = true;
      const data = await getShowcasePostList(stage.id).finally(() => {
        stage.loading = false;
      });

      if (!data || data.error_code) {
        return;
      }
      const postList = data?.data || [];

      stage.children = postList;
      if (!stage.activeKey?.length) {
        stage.activeKey = postList.map((post) => post.id);
      }

      this.list = [...this.list];
      postList.forEach((post) => {
        this.getShowcasePostBillboardList(post);
      });
    },
    async getShowcasePostBillboardList(post) {
      post.loading = true;
      const data = await getShowcasePostBillboardList(post.id).finally(() => {
        post.loading = false;
      });

      if (!data || data.error_code) {
        return;
      }
      post.children = data?.data || [];

      this.list = [...this.list];
    },

    // 岗位的增删改
    addPost(stage) {
      this.showEditPostModal(undefined, stage.id);
    },
    editPost(post, stage) {
      this.showEditPostModal(post.id, stage.id);
    },
    showEditPostModal(id, stageId) {
      if (this.$refs.editPostModalRef?.show) {
        this.$refs.editPostModalRef.show({
          id,
          stageId,
        });
      }
    },
    updatePostForce(params) {
      const [stage] = this.list.filter((i) => i.id === params.stageId);

      if (!stage?.id) {
        this.getShowcaseStageList();
        return;
      }
      this.getShowcasePostList(stage);
    },
    updatePost(params) {
      const [stage] = this.list.filter((i) => i.id === params.stageId);

      if (!stage?.id) {
        this.getShowcaseStageList();
        return;
      }

      if (!stage.children?.length) {
        this.getShowcasePostList(stage);
        return;
      }
      const [post] = stage.children.filter((i) => i.id === params.id);

      if (!post?.id || post.sort_num !== params.sort_num) {
        this.getShowcasePostList(stage);
        return;
      }

      Object.keys(params).forEach((key) => {
        post[key] = params[key];
      });
      this.list = [...this.list];
    },
    async changePostStatus(post) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await updateShowcasePostStatus(post.id, {
        is_open: (post.is_open + 1) % 2,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '状态修改失败');
        return;
      }
      this.$message.success(data?.message || '状态修改成功');
      post.is_open = (post.is_open + 1) % 2;

      this.list = [...this.list];
    },
    deletePost(post, stage) {
      this.$confirm({
        title: '提示',
        content: `确认删除岗位 "${post.post_name}" ？`,
        onOk: () => this.confirmDeletePost(post, stage),
      });
    },
    async confirmDeletePost(post, stage) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await deleteShowcasePost(post.id).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data?.message || '删除成功');
      if (stage.children?.length) {
        const index = stage.children.findIndex((i) => i.id === post.id);
        if (index > -1) {
          stage.children.splice(index, 1);
        }
      }

      this.list = [...this.list];
    },


    // 榜单的增删改
    addBillboard(post, stage) {
      this.showEditBillboardModal(undefined, post.id, stage.id);
    },
    editBillboard(billboard, post, stage) {
      this.showEditBillboardModal(billboard.id, post.id, stage.id);
    },
    showEditBillboardModal(id, postId, stageId) {
      if (this.$refs.editBillboardModalRef?.show) {
        this.$refs.editBillboardModalRef.show({
          id,
          postId,
          stageId,
        });
      }
    },
    updateBillboardForce(params) {
      const [stage] = this.list.filter((i) => i.id === params.stageId);
      if (!stage?.id) {
        this.getShowcaseStageList();
        return;
      }

      if (!stage.children?.length) {
        this.getShowcasePostList(stage);
        return;
      }

      const [post] = stage.children.filter((i) => i.id === params.postId);
      if (!post?.id) {
        this.getShowcasePostList(stage);
        return;
      }

      this.getShowcasePostBillboardList(post);
    },
    updateBillboard(params) {
      const [stage] = this.list.filter((i) => i.id === params.stageId);
      if (!stage?.id) {
        this.getShowcaseStageList();
        return;
      }

      if (!stage.children?.length) {
        this.getShowcasePostList(stage);
        return;
      }

      const [post] = stage.children.filter((i) => i.id === params.postId);
      if (!post?.id) {
        this.getShowcasePostList(stage);
        return;
      }

      if (!post.children?.length) {
        this.getShowcasePostBillboardList(post);
        return;
      }

      const [billboard] = post.children.filter((i) => i.id === params.id);
      if (!billboard?.id || billboard.sort_num !== params.sort_num) {
        this.getShowcasePostBillboardList(post);
        return;
      }

      Object.keys(params).forEach((key) => {
        billboard[key] = params[key];
      });
      this.list = [...this.list];
    },
    async changeBillboardStatus(billboard, post) {
      if (post.loading) {
        return;
      }
      post.loading = true;
      this.$forceUpdate();

      const data = await updateShowcasePostBillboardStatus(billboard.id, {
        is_open: (billboard.is_open + 1) % 2,
      }).finally(() => {
        post.loading = false;
      });
      if (!data || data.error_code) {
        this.$forceUpdate();
        this.$message.error(data?.message || '状态修改失败');
        return;
      }
      this.$message.success(data?.message || '状态修改成功');
      billboard.is_open = (billboard.is_open + 1) % 2;

      this.$forceUpdate();
    },
    deleteBillboard(billboard, post) {
      this.$confirm({
        title: '提示',
        content: `确认删除榜单 "${billboard.title}" ？`,
        onOk: () => this.confirmDeleteBillboard(billboard, post),
      });
    },
    async confirmDeleteBillboard(billboard, post) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await deleteShowcasePostBillboard(billboard.id).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '删除失败');
        return;
      }
      this.$message.success(data?.message || '删除成功');
      if (post.children?.length) {
        const index = post.children.findIndex((i) => i.id === billboard.id);
        if (index > -1) {
          post.children.splice(index, 1);
        }
      }

      this.list = [...this.list];
    },

    async importBillboardFile(billboard, post, { file }) {
      if (post.uploading) {
        return;
      }
      post.uploading = true;
      this.$forceUpdate();

      const params = new FormData();
      params.append('honour_post_list_id', billboard.id);
      params.append('file', file);

      const data = await uploadShowcasePostBillboardFile(params).finally(() => {
        post.uploading = false;
      });
      if (!data || data.error_code) {
        this.$forceUpdate();
        this.$message.error(data?.message || '上传失败');
        return;
      }
      this.$message.success(data.message || '上传成功');
      this.$forceUpdate();

      this.getShowcasePostBillboardList(post);
    },

  },
};
</script>

<style scoped lang="less">
::v-deep .ant-collapse-extra {
  float: none !important;
}

.preview-image ::v-deep .placeholder-img {
  max-width: 32px;
  max-height: 32px;
}
</style>
